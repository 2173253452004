import { registerApplication, start } from 'single-spa';

registerApplication({
  name: 'root-app',
  app: () =>
    // eslint-disable-next-line no-undef
    System.import('@cpce/root-app'),
  activeWhen: ['/'],
});

start({
  urlRerouteOnly: true,
});
